<ng-container *ngIf="isLoading === false ; else loadTemplate">

  <ng-container *ngIf="this.items ; else  noItemTemplate">

    <div class="create-packaging-dialog-cntr">
      <ng-container *ngIf="step1">
        <h1 mat-dialog-title>Stai per creare il tuo packaging</h1>
        <h4 mat-dialog-subtitle>Seleziona gli articoli che preferisci</h4>
        <div mat-dialog-content>
          <p-table [value]="items" [(selection)]="selectedItems" dataKey="ItemCode">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                <th>Codice</th>
                <th>Nome</th>
                <th>Quantità</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>
                  <p-tableCheckbox [value]="item" />
                </td>
                <td>{{ item.ItemCode }}</td>
                <td>{{ item.ItemName }}</td>
                <td>{{ item.Stock }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
          <p-button [disabled]="selectedItems.length < 1" pRipple (click)="submit(1)">Avanti</p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step2">
       <div class="item-cntr">
          <strong>Articolo </strong>
          <strong>Seleziona quantità </strong>
        </div>

        <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">

          <div formArrayName="itemsArray">
            <div *ngFor="let control of itemsArray.controls; let i = index" [formGroupName]="i" class="item-cntr">
              <label>{{ this.selectedItemConfiguration.itemHandlings[i].Item.Name }} - {{ this.selectedItemConfiguration.itemHandlings[i].Item.Code }}</label>
              <input pInputText type="number" formControlName="qty" placeholder="Inserisci QTY" style="max-width: 100px;" />
            </div>
          </div>
        </form>

        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
          <p-button pRipple (click)="submit(2)">Avanti</p-button>
        </div>

      </ng-container>

      <ng-container *ngIf="step3">
        <h4 mat-dialog-subtitle>
          Seleziona il packaging a cui vuoi inserire gli articoli o creane uno
          nuovo.
        </h4>

        <div class="item-cntr">
          <p-dropdown style="width: 70%" [options]="packagies" [(ngModel)]="selectedPack"
            optionLabel="Name"></p-dropdown>
          <p-button label="Crea" icon="pi pi-plus" iconPos="right" (click)="navigateToPackaging()"></p-button>
        </div>

        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
          <p-button pRipple (click)="submit(3)">Salva</p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step4">
        <h4 mat-dialog-subtitle>
          Hai salvato corretamente questi articoli nel packaging selezionato:
        </h4>

        <ng-container *ngFor="let item of selectedItems">
          <div class="item-cntr">
            <strong>{{ item.code }} - {{ item.description }}</strong>
          </div>
        </ng-container>

        <div mat-dialog-actions class="button-cntr">
          <p-button style="justify-content: end" severity="danger" (click)="onNoClick()">Concludi</p-button>
        </div>
      </ng-container>
    </div>

  </ng-container>

  <ng-template #noItemTemplate>

    <div class="create-packaging-dialog-cntr" style="min-height: 150px;">

      <h4 mat-dialog-title> Mi dispiace, nessun item in stock per poter creare un packaging.</h4>

      <div mat-dialog-actions class="button-cntr">
        <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
      </div>

    </div>

  </ng-template>
</ng-container>


<ng-template #loadTemplate>

  <h1 mat-dialog-title>Caricamento...</h1>

</ng-template>
