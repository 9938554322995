import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderPackagingComponent } from './work-order-packaging.component';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {InputTextModule} from "primeng/inputtext";
import { CtButtonModule, CtFrameworkModule, DisableArrowKeysModule } from '@ctsolution/ct-framework';
import { DropdownModule } from 'primeng/dropdown';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatListModule } from '@angular/material/list';
import { RippleModule } from 'primeng/ripple';
import { StatusToggleComponentModule } from '../../../handling/order-handling-wizard/order-handling-wizard-components/status-toggle-component/status-toggle-component.module';


@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DisableArrowKeysModule,
    DropdownModule,
    CommonModule,
    MatCardModule,
    CtButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyProgressBarModule,
    MatLegacyButtonModule,
    MatLegacyChipsModule,
    FlexModule,
    FlexLayoutModule,
    CtFrameworkModule,
    StatusToggleComponentModule,
    RippleModule,
    MatLegacyMenuModule,
  ],
  declarations: [WorkOrderPackagingComponent],
  exports : [WorkOrderPackagingComponent]
})
export class WorkOrderPackagingModule { }
