import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { CtWebapiService } from '@ctsolution/ct-webapi';
import { WorkOrderPackagingConfiguration } from './work-order-packaging.configuration';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { CtBinaryOperator, CTModelDatatableFilter } from '@ctsolution/ct-framework';
import { switchMap } from 'rxjs';
import { CTManagerItemStock } from 'projects/ct-manager/src/app/_core/classes/ct-manager-item-stock';
import { ItemHandling } from 'projects/ct-manager/src/app/_core/classes/item-handling';
import { SelectedItemsConfiguration } from '../../../handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.configuration';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-work-order-packaging',
  templateUrl: './work-order-packaging.component.html',
  styleUrls: ['./work-order-packaging.component.scss']
})
export class WorkOrderPackagingComponent {

  items: CTManagerItemStock[] | null = null;
  isLoading: boolean = true;
  selectedItemConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration.create();

  packagies : any[] | null = [];
  selectedPack: any;
  itemForm: FormGroup; // Il form dinamico


  selectedItems = [];
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;

  constructor(
    public dialogRef: MatDialogRef<WorkOrderPackagingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkOrderPackagingConfiguration,
    private router: Router,
    private generalService: CTMGeneralService,
    private formBuilder: FormBuilder
  ) {

    this.itemForm = this.formBuilder.group({
      itemsArray: this.formBuilder.array([]) // FormArray per gestire dinamicamente i controlli
    });

  }

  ngOnInit(): void {

    if (!this.data.WorkOrder?.Oid) return;

    this.generalService.getList({
      controller: 'location',
      filters: [
        CTModelDatatableFilter.create()
          .setField('Type.MacroType')
          .setValue(1)
          .setOperatorType(CtBinaryOperator.Equal),
        CTModelDatatableFilter.create()
          .setField('WorkOrder.Oid')
          .setValue(this.data.WorkOrder.Oid)
          .setOperatorType(CtBinaryOperator.Equal),
      ]
    }).pipe(
      switchMap((response: any) => {
        // Recupera l'Oid dalla prima chiamata
        const locationOid = response.Result.DataSource[0].Oid;

        // Effettua la seconda chiamata utilizzando l'Oid
        return this.generalService.getList({
          controller: 'locationstock',
          filters: [
            CTModelDatatableFilter.create()
              .setField('Location.Oid')
              .setValue(locationOid)
              .setOperatorType(CtBinaryOperator.Equal),
          ]
        });
      })
    ).subscribe((locationStockResponse: any) => {

      this.items = [...locationStockResponse.Result.DataSource]
      this.isLoading = false;

    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateSelection(selectedItemsConfiguration: SelectedItemsConfiguration) {

    selectedItemsConfiguration
      .selectItems(this.selectedItems);

  }

  updatePackage(){

    debugger
    this.generalService.getList({
      controller: 'location',
      filters: [

      ]
    }).subscribe((response: any) => {

      debugger
      this.packagies = [...response.Result.DataSource];

    })

  }


  submit(num: number) {

    if (this.selectedItems.length < 1) return;

    switch (num) {

      case 1:
        this.updateSelection(this.selectedItemConfiguration);
        this.populateForm();
        this.step1 = false;
        this.step2 = true;
        break;

      case 2:
        if (this.onSubmitStep2()) {
          this.updatePackage();
          this.step2 = false;
          this.step3 = true;
        }

        break;

      case 3:
        this.step3 = false;
        this.step4 = true;
        break;

      default:
        break;
    }

  }

  navigateToPackaging() {

    this.router.navigate(['/location/box/create']);

    this.dialogRef.close();

  }

  populateForm() {

    const itemsArray = this.itemForm.get('itemsArray') as FormArray;

    this.selectedItemConfiguration.itemHandlings.forEach(item => {
      itemsArray.push(
        this.formBuilder.group({
          qty: [item.StartQty, [Validators.required, Validators.min(0), Validators.max(item.CTManagerItemStock?.Stock!)]], // Aggiungi la qty con validazione
        })
      );
    });
  }

  // Getter per ottenere il FormArray
  get itemsArray(): FormArray {
    return this.itemForm.get('itemsArray') as FormArray;
  }

  // Funzione di submit
  onSubmitStep2() {

    if (this.itemForm.valid) {
      // Aggiorna la qty per ogni item in base al form
      this.selectedItemConfiguration.itemHandlings.forEach((item, index) => {
        item.StartQty = this.itemsArray.at(index).get('qty')?.value;
      });

      return true;
    } else {
      return false;
    }
  }
}
